import { ChakraProvider, ColorModeScript, useColorMode } from "@chakra-ui/react";
import { RainbowKitProvider, darkTheme, lightTheme } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import Colors from "./DSL/Colors/Colors";
import Fonts, { Type } from "./DSL/Fonts/Fonts";
import theme from "./DSL/Theme";
import { ToastContainer } from "./DSL/Toast/Toast";

import { WagmiProvider, http } from 'wagmi'
import { getDefaultConfig } from '@rainbow-me/rainbowkit'
import { base, baseSepolia } from 'wagmi/chains'
import { coinbaseWallet } from 'wagmi/connectors';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const customLightTheme = lightTheme({
  borderRadius: "none",
  fontStack: "system",
  accentColor: Colors.yellow[100],
  overlayBlur: "small",
});
customLightTheme.fonts.body = Type.ComicSans;
customLightTheme.colors.modalBackground = Colors.yellow[50];
customLightTheme.colors.modalBorder = "black";

const customDarkTheme = darkTheme({
  borderRadius: "none",
  fontStack: "system",
  accentColor: Colors.gray[300],
  overlayBlur: "small",
});
customDarkTheme.fonts.body = Type.ComicSans;
customDarkTheme.colors.modalBackground = Colors.purple[700];
customDarkTheme.colors.modalBorder = "white";

const config = getDefaultConfig({
  appName: 'Own The Doge: Pixel Portal',
  projectId: '573dfdb7fc28f71be6bc4e69d81fe1a6',
  chains: [base, baseSepolia],
  // connectors: [
  //   coinbaseWallet({ appName: 'Own The Doge: Pixel Portal', preference: 'smartWalletOnly' }),
  // ],
  transports: {
    [base.id]: http(),
    [baseSepolia.id]: http()
  },
})

const queryClient = new QueryClient()

const Index = () => {
  const { colorMode } = useColorMode();
  return (
    <WagmiProvider config={config}>
    <QueryClientProvider client={queryClient}>
      <RainbowKitProvider theme={colorMode === "light" ? customLightTheme : customDarkTheme}>
      <Fonts />
      <App />
      <ToastContainer />
       </RainbowKitProvider>
    </QueryClientProvider>
  </WagmiProvider>
  );
};



const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
  <>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ChakraProvider theme={theme} resetCSS>
        <Index />
      </ChakraProvider>
      </>
  </React.StrictMode>
);